<template>
  <div class="experiences" data-view>
    <hero-with-image
      title="neera experience"
      description="
    <p>
      with one-of-a-kind experiences, we<br />
      look to combine mindful, fun, active<br />
      and educational activities to elevate<br />
      your stay while at <span class='saoltext'>‘neera’</span>.
    </p>
    "
      :imageUrl="require('@/assets/experiences/experience.png')"
      class="mb-18 lg:mb-20 lg:mt-8"
    />
    <!-- <item-navigation :items="navigations" default-tab="activities" /> -->
    <div class="space-y-8 lg:space-y-16 mb-16 lg:mg-0">
      <experience-card
        data-aos="fade-up"
        id="activities"
        title='experience <span style="white-space: nowrap">infinity–edge pool</span>'
        :imageUrl="
          require('@/assets/experiences/EXPERIENCES_02-infinityedgePool.png')
        "
        imgPosition="left"
      >
        <p class="neera-text-green text-np-xl">
          length of pool 12.5 meters <br />
          depth of pool 1.2 meters
        </p>
        <p class="neera-text-green content-para">
          spend some time floating in our saltwater infinity-edge pool that
          extends over 64 square meters of your experience into the mesmerizing
          <span class="whitespace-nowrap">Tha Chin River</span>. a place where
          you can slow down and take a few laps after a busy day.
        </p>
      </experience-card>

      <experience-card
        data-aos="fade-up"
        id="activities"
        title='experience <span style="white-space: nowrap">neera activities</span>'
        :imageUrl="
          require('@/assets/experiences/experiences_01-neeraactivities.png')
        "
        imgPosition="right"
      >
        <p class="neera-text-green content-para">
          with one-of-a-kind experiences, we look to combine mindful, fun,
          active, and educational activities to elevate your stay while at
          <span class="saoltext">‘neera’</span>.
        </p>
        <router-link
          to="/experiences/activities"
          class="flex saoltext neera-text-glimps pt-4"
          ><p class="saoltext">explore our activities</p>
          <div class="flex transition duration-200 anchor-arrow px-2">
            <img src="@/assets/neera-arrow.svg" /></div
        ></router-link>
      </experience-card>

      <experience-card
        data-aos="fade-up"
        id="eco-wedding"
        title='experience <span style="white-space: nowrap">eco–wedding</span>'
        :imageUrl="
          require('@/assets/experiences/experiences_02-ecowedding.png')
        "
        imgPosition="left"
      >
        <p class="neera-text-green content-para">
          craft up the wedding of your dreams in either our outdoor
          <span class="saoltext whitespace-nowrap">‘neera green space’</span>
          with natural lighting and an amazing river view of the
          <span class="whitespace-nowrap">Tha Chin River</span>
          or have a private ceremony indoors with our unique & eco-decorations.
        </p>
        <router-link
          to="/experiences/eco-wedding"
          class="flex saoltext neera-text-glimps pt-4"
          ><p class="saoltext">explore eco-wedding</p>
          <div class="flex transition duration-200 anchor-arrow px-2">
            <img src="@/assets/neera-arrow.svg" /></div
        ></router-link>
      </experience-card>

      <experience-card
        data-aos="fade-up"
        id="spaces"
        title='experience <span style="white-space: nowrap">neera spaces</span>'
        :imageUrl="
          require('@/assets/experiences/experiences_03-neeraspaces.png')
        "
        imgPosition="right"
      >
        <p class="neera-text-green content-para">
          <span class="saoltext">‘neera’</span> creates spaces to offer deeper
          thoughts and connections through nature, design, and mindfulness.
        </p>
        <router-link
          to="/experiences/spaces"
          class="flex saoltext neera-text-glimps pt-4"
          ><p class="saoltext">explore our spaces</p>
          <div class="flex transition duration-200 anchor-arrow px-2">
            <img src="@/assets/neera-arrow.svg" /></div
        ></router-link>
      </experience-card>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
// import ItemNavigation from "@/components/ItemNavigation.vue";
import ExperienceCard from "./components/ExperienceCard.vue";
import AOS from "aos";
import HeroWithImage from "../../components/HeroWithImage.vue";

export default {
  name: "Home",
  components: {
    HeroWithImage,
    // ItemNavigation,
    ExperienceCard,
  },
  created() {
    const title = "neera experience";
    const description =
      "with one-of-a-kind experiences, we look to combine mindful, fun, active and educational activities to elevate your stay while at ‘neera’.";
    // const titleEl = document.querySelector("head title");
    const mTitleEl = document.querySelector('head meta[name="title"]');
    const descEl = document.querySelector('head meta[name="description"]');
    const twTitleEl = document.querySelector('head meta[name="twitter:title"]');
    const twDescEl = document.querySelector(
      'head meta[name="twitter:description"]'
    );
    const ogTitleEl = document.querySelector('head meta[property="og:title"]');
    const ogDescEl = document.querySelector('head meta[property="og:description"]');
    // titleEl.textContent = title;
    mTitleEl.setAttribute("content", title);
    twTitleEl.setAttribute("content", title);
    ogTitleEl.setAttribute("content", title);
    descEl.setAttribute("content", description);
    twDescEl.setAttribute("content", description);
    ogDescEl.setAttribute("content", description);
  },
  setup() {
    const startDateValue = ref([]);
    const endDateValue = ref([]);
    const formatter = ref({
      date: "DD MMM YYYY",
      month: "MMM",
    });

    return {
      startDateValue,
      endDateValue,
      formatter,
    };
  },
  mounted() {
    AOS.init({
      once: true,
      duration: 2000,
    });
  },
  data() {
    return {
      navigations: [
        {
          name: "activities",
          link: "activities",
        },
        {
          name: "eco–wedding",
          link: "eco-wedding",
        },
        {
          name: "spaces",
          link: "spaces",
        },
      ],
    };
  },
};
</script>

<style scoped>
.saoltext {
  font-family: "Saol Text" !important;
}

.menu {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #5c623f;
}

#experience-content {
  border-top: 1px solid #cecdc8;
}

a:hover .anchor-arrow {
  transform: translateX(0.25rem);
}
</style>
